import FunktionalGroteskLightWoff1 from '@/assets/fonts/FunktionalGrotesk-Light.woff';
import FunktionalGroteskLightWoff2 from '@/assets/fonts/FunktionalGrotesk-Light.woff2';
import CanelaThinWoff1 from '@/assets/fonts/Canela-Thin-Web.woff';
import CanelaThinWoff2 from '@/assets/fonts/Canela-Thin-Web.woff2';
import CanelaThinItalicWoff1 from '@/assets/fonts/Canela-ThinItalic-Web.woff';
import CanelaThinItalicWoff2 from '@/assets/fonts/Canela-ThinItalic-Web.woff2';

export default `
 @font-face {
    font-family: 'Funktional Grotesk';
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    src: url(${FunktionalGroteskLightWoff2}) format('woff2'),
      url(${FunktionalGroteskLightWoff1}) format('woff');
  }

  @font-face {
    font-family: 'Canela';
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    src: url(${CanelaThinWoff2}) format('woff2'),
      url(${CanelaThinWoff1}) format('woff');
  }

  @font-face {
    font-family: 'Canela';
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    src: url(${CanelaThinItalicWoff2}) format('woff2'),
      url(${CanelaThinItalicWoff1}) format('woff');
  }
`;
