import React from 'react';
import { ThemeProvider } from 'styled-components';

import PageWrapper from './PageWrapper';
import Head from '@/components/Head';
import Header from '@/components/Header';
import Footer from '@/components/Footer';

import GlobalStyles from '@/styles/global';
import theme from '@/styles/theme';

export default ({ children, location, pageContext }) => {
  const { pageSpacing = 'medium' } = pageContext;

  return (
    <>
      <Head />
      <GlobalStyles />

      <ThemeProvider theme={theme}>
        <Header location={location} />

        <PageWrapper spacing={pageSpacing}>{children}</PageWrapper>

        <Footer />
      </ThemeProvider>
    </>
  );
};
