import React from 'react';
import Box from '@/atoms/Box';

import theme from '@/styles/theme';

export default ({ spacing = 'medium', ...props }) => (
  <Box
    as="main"
    $position="relative"
    $flex="1 1 auto"
    pt={theme.spacing.mainContent[spacing].top}
    pb={theme.spacing.mainContent[spacing].bottom}
    {...props}
  />
);
