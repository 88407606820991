import { css } from 'styled-components';

import { media, rem } from '@/styles/utils';
import theme from '@/styles/theme';

const reset = css`
  font-weight: normal;
  text-decoration: none;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const h1 = css`
  ${reset}
  font-size: ${rem(22)};
  line-height: ${rem(26)};
  font-family: ${theme.font.serif};

  ${media.md`
    font-size: ${rem(40)};
    line-height: ${rem(48)};
  `}
`;

export const h2 = css`
  ${reset}
  font-size: ${rem(22)};
  line-height: ${rem(26)};
  font-family: ${theme.font.serif};

  ${media.md`
    font-size: ${rem(32)};
    line-height: ${rem(40)};
  `}
`;

export const h3 = css`
  ${reset}
  font-size: ${rem(13)};
  line-height: ${rem(16)};
  font-family: ${theme.font.serif};
  font-variant: small-caps;
  font-feature-settings: "smcp" on;

  ${media.md`
    font-size: ${rem(16)};
    line-height: ${rem(18)};
  `}
`;

export const pLarge = css`
  ${reset}
  font-size: ${rem(16)};
  line-height: ${rem(18)};
  font-family: ${theme.font.serif};
`;

export const p = css`
  ${reset}
  font-size: ${rem(14)};
  line-height: ${rem(20)};
`;

export const pSmall = css`
  ${reset}
  font-size: ${rem(12)};
  line-height: ${rem(16)};
`;
