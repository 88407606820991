import React, { useState, useEffect, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import NavItem from './NavItem';
import Box from '@/atoms/Box';

import { mapToSingleObject } from '@/utils/helpers';
import { getUri } from '@/utils/routing';

const NavList = styled(Box)`
  list-style: none;
`;

const isActive = (itemPath, locationPath) =>
  itemPath === '/' ? locationPath === '/' : locationPath.startsWith(itemPath);

export default ({ location, ...props }) => {
  const [activeItem, setActiveItem] = useState(-1);

  const { data } = useStaticQuery(graphql`
    query {
      data: allSanitySiteSettingsNavigation {
        edges {
          node {
            mainNavigationItems {
              title
              slug {
                current
              }
            }
          }
        }
      }
    }
  `);

  const navItems = useMemo(() => {
    const { mainNavigationItems } = mapToSingleObject(data);

    return [
      {
        path: '/',
        title: 'work',
      },
      ...mainNavigationItems.map(({ slug, ...item }) => {
        const path = getUri(slug.current, 'page');

        return {
          path,
          ...item,
        };
      }),
    ];
  }, [data]);

  useEffect(() => {
    if (location.pathname) {
      setActiveItem(
        navItems.findIndex(item => isActive(item.path, location.pathname))
      );
    }
  }, [navItems, location.pathname]);

  return (
    <Box as="nav" role="navigation" mr={[2, 2, 3]} {...props}>
      <NavList as="ul" $display="flex">
        {navItems.map((item, i) => (
          <NavItem key={item.path} isActive={i === activeItem} {...item} />
        ))}
      </NavList>
    </Box>
  );
};
