import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Text from '@/atoms/Text';

const Item = styled(Text)`
  font-style: ${props => (props.isActive ? 'normal' : 'italic')};

  :last-child {
    margin-right: 0;
  }

  a {
    text-decoration: none;
  }
`;

export default ({ title, path, isActive, ...props }) => (
  <Item as="li" t={1} mr={[1, 1, 1.25]} isActive={isActive} {...props}>
    <Link to={path}>{title}</Link>
  </Item>
);
