import React from 'react';
import styled from 'styled-components';

import Text from '@/atoms/Text';

import theme from '@/styles/theme';

const Logo = styled(Text)`
  a {
    text-decoration: none;
    color: ${theme.color.black};
  }
`;

export default () => {
  return (
    <Logo as="h1" display="none">
      Lucas Schneider
    </Logo>
  );
};
