import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';

import Text from '@/atoms/Text';
import Grid from '@/atoms/Grid';

import { mapToSingleObject } from '@/utils/helpers';
import { getUri } from '@/utils/routing';
import { scale } from '@/styles/utils';

const LinkList = styled(Text)`
  list-style: none;

  li {
    display: inline;
    margin-left: ${scale(1)};
  }
`;

export default props => {
  const today = new Date();

  const { data } = useStaticQuery(graphql`
    query {
      data: allSanitySiteSettingsInformation {
        edges {
          node {
            imprintPage {
              title
              slug {
                current
              }
            }
            privacyPolicyPage {
              title
              slug {
                current
              }
            }
          }
        }
      }
    }
  `);

  const { imprintPage, privacyPolicyPage } = mapToSingleObject(data);

  return (
    <Grid.Container as="footer" $flexGrow="0" pb={[1.5, 1.5, 2]} {...props}>
      <Text t={-1} $display="inline-block">
        © {today.getFullYear()}
      </Text>

      <LinkList as="ul" t={-1} $display="inline-block">
        {imprintPage && (
          <li>
            <Link to={getUri(imprintPage.slug.current, 'page')}>
              {imprintPage.title}
            </Link>
          </li>
        )}

        {privacyPolicyPage && (
          <li>
            <Link to={getUri(privacyPolicyPage.slug.current, 'page')}>
              {privacyPolicyPage.title}
            </Link>
          </li>
        )}
      </LinkList>
    </Grid.Container>
  );
};
