import styled from 'styled-components';

import Box from '@/atoms/Box';

import { scale } from '@/styles/utils';
import { pLarge, p, pSmall } from '@/styles/typography';

export default styled(Box).attrs(props => ({
  as: props.as || 'p',
  t: props.t || 0,
  whiteSpace: props.whiteSpace || 'normal',
}))`
  ${props => props.t === -1 && pSmall}
  ${props => props.t === 0 && p}
  ${props => props.t === 1 && pLarge}

  hyphens: ${props => (props.hyphens ? 'auto' : 'none')};
  white-space: ${props => props.whiteSpace};
  max-width: ${scale(40)};
`;
