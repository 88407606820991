export default {
  scale: 1, // (in rem) => scale(1) = 16px
  breakpoints: ['36em', '48em', '62em', '75em'],
  breakpointNames: ['xs', 'sm', 'md', 'lg', 'xl'],

  space: [0, 2, 4, 6, 12],

  grid: {
    gutter: [1, 1, 1.5],
    outerMargin: [1, 1, 3],
    // maxWidth: 100,
  },

  spacing: {
    mainContent: {
      medium: {
        top: [4, 4, 5],
        bottom: [5, 5, 7],
      },
      large: {
        top: [8, 8, 10],
        bottom: [5, 5, 7],
      },
    },
  },

  color: {
    white: '#fff',
    black: '#000',
    gray: '#e5e4e4',
    grayDark: 'rgba(0, 0, 0, 0.3)',
  },

  font: {
    sans: '"Funktional Grotesk", sans-serif',
    serif: 'Canela, serif',
  },
};
