import React from 'react';
import Grid from '@/atoms/Grid';
import Navigation from './Navigation';
import Logo from './Logo';

export default ({ location, ...props }) => {
  return (
    <Grid.Container
      $display="flex"
      $flexGrow="0"
      $alignItems="center"
      pt={[1.5, 1.5, 2]}
      {...props}
    >
      <Logo />

      <Navigation location={location} />
    </Grid.Container>
  );
};
